import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Minimum Voting Threshold`}</h1>
    <ul>
      <li parentName="ul">{`In `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{`, there is a minimum threshold for voting behavior for the `}<a parentName="li" {...{
          "href": "/participant",
          "title": "participant"
        }}>{`participant`}</a>{` to be included in the automatic clustering analysis performed by the `}<a parentName="li" {...{
          "href": "/Math-Module",
          "title": "Math Module"
        }}>{`Math Module`}</a>{` and displayed in the `}<a parentName="li" {...{
          "href": "/report",
          "title": "report"
        }}>{`report`}</a>{``}</li>
      <li parentName="ul">{`In the `}<a parentName="li" {...{
          "href": "/notebooks",
          "title": "notebooks"
        }}>{`notebooks`}</a>{` this number is a constant and thus can be changed to include participants who only voted 1 time by the analyst`}</li>
      <li parentName="ul">{`This is a 'magic number', and it is frequently 7 votes but sometimes stricter`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      